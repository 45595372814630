import "./Features.scss";

// lp data
import lpData from "../Data/LPData.js";

// wrapper
import SectionWrapper from "./Wrappers/SectionWrapper";
import FeatureCardsWrapper from "./Wrappers/FeatureCardsWrapper";

const Features = () => {
  const sectionData = lpData.Features;

  if (!sectionData.hidden) {
    return (
      <SectionWrapper
        bgColor={sectionData.background}
        fontColor={sectionData.fontColor}
        tight={true}
        wrapperClass="features-wrapper"
        containerClass="features-container"
        sectionTitle={sectionData.sectionTitle}
        sectionDescription={sectionData.sectionDescription}
      >
        <FeatureCardsWrapper
          features={sectionData.features}
          cardBackground={sectionData.cardBackground}
          cardFontColor={sectionData.cardFontColor}
        />
      </SectionWrapper>
    );
  }

  
};

export default Features;
