import "./Values.scss";

// wrapper
import SectionWrapper from "./Wrappers/SectionWrapper";
import SectionRowsWrapper from "./Wrappers/SectionRowsWrapper";

// lp Data
import lpData from "../Data/LPData";

const Values = () => {
  const sectionData = lpData.Values;

  return (
    <SectionWrapper
      bgColor={sectionData.background}
      fontColor={sectionData.fontColor}
      tight={true}
      wrapperClass="values-wrapper"
      containerClass="values-container"
      sectionTitle={sectionData.sectionTitle}
      sectionDescription={sectionData.sectionDescription}
    >
      <SectionRowsWrapper items={sectionData.items} swap={true} lines={true} lineColor={"white"} />
    </SectionWrapper>
  )
}

export default Values;