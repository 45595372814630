import { motion } from "framer-motion";

const Animated = ({ children, className = "", style, direction, delay = 0 }) => {
  const variants = {
    up: {
      initial: { y: 50, opacity: 0 },
      whileInView: { y: 0, opacity: 1 },
    },
    fromRight: {
      initial: { x: 50, opacity: 0 },
      whileInView: { x: 0, opacity: 1 },
    },
    fromLeft: {
      initial: { x: -50, opacity: 0 },
      whileInView: { x: 0, opacity: 1 },
    },
  };

  return (
    <motion.div
      className={className}
      initial={variants[direction].initial}
      whileInView={variants[direction].whileInView}
      viewport={{ once: true }}
      margin="-1000px"
      style={style}
      transition={{ duration: 0.8, delay: delay, type: "spring", mass: 1  }}
    >
      {children}
    </motion.div>
  );
};

export default Animated;
