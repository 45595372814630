import "./SectionRowsWrapper.scss";

// animation
import Animated from "../../Components/Animated";

const SectionRowsWrapper = ({
  items,
  swap = false,
  lines = false,
  lineColor = "white",
}) => {
  return (
    <div className="section-rows-wrapper">
      {items.map((item, index) => {
        return (
          <>
            <div className={`section-row-wrapper`} key={index}>
              <div
                className={`row-contents  ${
                  swap && index % 2 != 0 ? "swap" : ""
                }`}
              >
                <Animated
                  direction={swap && index % 2 != 0 ? "fromRight" : "fromLeft"}
                  delay={0.3}
                  className="text-section"
                >
                  {item.preTitle && (
                    <span className="pre-title opacity">{item.preTitle}</span>
                  )}
                  <span className="title">{item.title}</span>
                  <p className="description">{item.description}</p>
                  {item.body && (
                    <Animated
                      direction={
                        swap && index % 2 != 0 ? "fromRight" : "fromLeft"
                      }
                      delay={0.3}
                      className="body"
                    >
                      {item.body}
                    </Animated>
                  )}
                </Animated>

                <Animated
                  direction={swap && index % 2 != 0 ? "fromLeft" : "fromRight"}
                  delay={0.5}
                  className="image-section"
                >
                  <img src={item.image} alt="" className="image" />
                </Animated>
              </div>

              {lines && items.length > index + 1 && (
                <div
                  className="line"
                  style={{
                    borderBottom: `1px solid ${lineColor}`,
                  }}
                />
              )}
            </div>
          </>
        );
      })}
    </div>
  );
};

export default SectionRowsWrapper;
