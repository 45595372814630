import "./SectionWrapper.scss";

// animation
import Animated from "../../Components/Animated";

const SectionWrapper = ({
  children,
  bgColor,
  fontColor = null,
  tight = false,
  wrapperClass,
  containerClass,
  sectionTitle = null,
  sectionDescription = null,
  card = true,
}) => {
  return (
    <Animated direction="up" delay={0.5}>
      <div
        className={`section-wrapper ${wrapperClass} ${card ? "card" : ""}`}
        style={{ background: bgColor, color: fontColor ? fontColor : null }}
      >
        <div className={`section-container ${tight ? "tight" : ""}`}>
          {(sectionTitle || sectionDescription) && (
            <div className="section-info-section">
              {sectionTitle && (
                <span className="section-title">{sectionTitle}</span>
              )}
              {sectionDescription && (
                <p className="section-description">{sectionDescription}</p>
              )}
            </div>
          )}

          <div className={`children ${containerClass}`}>{children}</div>
        </div>
      </div>
    </Animated>
  );
};

export default SectionWrapper;
