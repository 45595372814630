import React from "react";
import "./LP.scss";

// components
import Header from "../Sections/Header";
import Hero from "../Sections/Hero";
import Introduction from "../Sections/Introduction";
import WhatYouCanCreate from "../Sections/WhatYouCanCreate";
import Values from "../Sections/Values";
import Features from "../Sections/Features";
import Creators from "../Sections/Creators";
import Contents from "../Sections/Contents";
import Footer from "../Sections/Footer";
import CallToActionFooter from "../Sections/CallToActionFooter";
import Signup from "../Sections/Signup";

const LP = () => {

  return (
    <div className="lp-wrapper">
      <Header />
      <Hero />
      <CallToActionFooter />
      <Introduction />
      <Contents />
      <WhatYouCanCreate />
      <Values />
      <Features />
      <Creators />
      <Signup />
      <Footer />
    </div>
  );
};

export default LP;
