import logo from "./logo.svg";
import "./App.scss";

// router
import { router } from "./Router/router";

// routing
import { createRoot } from "react-dom/client";
import { RouterProvider } from "react-router-dom";

function App() {
  createRoot(document.getElementById("root")).render(
    <div className="site-wrapper">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
