import React, { useRef } from "react";
import "./CallToActionFooter.scss";

// motion
import { motion, useScroll, useMotionValueEvent } from "framer-motion";

// icon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

// wrapper
import SectionWrapper from "./Wrappers/SectionWrapper";

// lp Data
import lpData from "../Data/LPData";

const CallToActionFooter = () => {
  const sectionData = lpData.cta;

  const { scrollY } = useScroll();
  const [isVisible, setIsVisible] = React.useState(true);
  const inputRef = useRef(null);

  const [email, setEmail] = React.useState("");
  const [emailValidation, setEmailValidation] = React.useState(false);

  // detect scroll
  useMotionValueEvent(scrollY, "change", (latest) => {
    if (scrollY?.current < scrollY?.prev) {
      setIsVisible(true);
    } else if (scrollY?.current > 100 && scrollY?.current > scrollY?.prev) {
      if (document.activeElement === inputRef.current || email != "") return;
      setIsVisible(false);
    }
  });

  // validate email

  const validateEmailString = (email) => {
    const validation = email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  
    return validation;
  };

  // effects

  React.useEffect(() => {
    setEmailValidation(validateEmailString(email));
  }, [email])



  return (
    <div
      className={`cta-footer-wrapper ${isVisible ? "" : "hidden"}`}
      onClick={() => {
        setIsVisible(true);
      }}
      onMouseEnter={() => {
        setIsVisible(true);
      }}
    >
      <div className="cta-footer-container">
        {
          !isVisible && (
            <div className="handle"></div>
          )
        }
        <p className="description">{ isVisible ? sectionData.descriptionVisible : sectionData.descriptionHidden}</p>
        <div className="form-section">
          <input
            ref={inputRef}
            className="form-input"
            placeholder="メールアドレス"
            type="text"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <div
            className={`button ${emailValidation ? "active" : ""}`}
            onClick={() => {
              if (!emailValidation) return;
              window.open(`${sectionData.urlBase}${email}`);
            }}
          >
            <FontAwesomeIcon icon={faArrowRight} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallToActionFooter;
