import "./Introduction.scss";

// fa
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

// wrapper
import SectionWrapper from "./Wrappers/SectionWrapper";

// lp Data
import lpData from "../Data/LPData";

const Introduction = () => {
  const sectionData = lpData.Introduction;

  if (!sectionData.hidden) {
    return (
      <SectionWrapper
        bgColor={sectionData.background}
        fontColor={sectionData.fontColor}
        tight={true}
        card={false}
        wrapperClass="introduction-wrapper"
        containerClass="introduction-container"
      >
        <div className="quote-section">
          <span className="quote">{sectionData.quote}</span>
          <span className="quote-emoji">{sectionData.quoteEmoji}</span>
        </div>
  
        <div className="description-section">
          <p>{sectionData.description}</p>
        </div>
  
        <div className="points-section">
          {sectionData.points.map((point, index) => {
            return (
              <div className="point" key={index}>
                <div
                  className="icon-wrapper"
                  style={{
                    background: sectionData.pointsIconColor,
                  }}
                >
                  <FontAwesomeIcon icon={faCheck} className="icon" />
                </div>
  
                <p className="text">{point}</p>
              </div>
            );
          })}
        </div>
      </SectionWrapper>
    );
  }

  
};

export default Introduction;
