import "./Header.scss";

// lp Data
import lpData from "../Data/LPData";

const Header = () => {
  return (
    <div className="lp-header-wrapper">
      <div className="container lp-header-container">
        <div className="left">
          <div className="pulp-logo-wrapper">
            <img src={lpData.appIcon} className="logo" alt="" />
            <img src={lpData.appIconText} className="text" alt="" />
          </div>
        </div>
        <div className="right">
          <div className="navigation-wrapper"></div>
          <div className="pre-signup-button-wrapper"></div>
        </div>
      </div>
    </div>
  );
};

export default Header;
