import {
  createBrowserRouter,
} from "react-router-dom";

// screens
import LP from "../Screens/LP";

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <LP />
    ),
  },
]);