import "./WhatYouCanCreate.scss";

// wrapper
import SectionWrapper from "./Wrappers/SectionWrapper";
import SectionRowsWrapper from "./Wrappers/SectionRowsWrapper";

// lp Data
import lpData from "../Data/LPData";

const WhatYouCanCreate = () => {
  const sectionData = lpData.WhatYouCanCreate;

  if (!sectionData.hidden) {
    return (
      <SectionWrapper
        bgColor={sectionData.background}
        fontColor={sectionData.fontColor}
        tight={true}
        wrapperClass="what-you-can-create-wrapper"
        containerClass="what-you-can-create-container"
        sectionTitle={sectionData.sectionTitle}
        sectionDescription={sectionData.sectionDescription}
      >
        <SectionRowsWrapper items={sectionData.items} />
      </SectionWrapper>
    );
  }

  
};

export default WhatYouCanCreate;
