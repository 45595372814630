import "./Hero.scss";

// wrapper
import SectionWrapper from "./Wrappers/SectionWrapper";

// svg
import { ReactSVG } from "react-svg";

// lp Data
import lpData from "../Data/LPData";

const Hero = () => {
  const sectionData = lpData.Hero;

  return (
    <SectionWrapper
      bgColor={sectionData.background}
      fontColor={sectionData.fontColor}
      wrapperClass="hero-wrapper"
      containerClass="hero-container"
      card={false}
    >
      <div className="image-section">
        <img src={sectionData.mainImage} className="main-image" alt="" />
        <div className="app-icon-wrapper">
          <img src={lpData.appIconWithBg} alt="" className="app-icon" />
        </div>
      </div>
      <div className="messages-section">
        <ReactSVG src={lpData.appIconTextGradient} className="icon" />
        <p className="main-message">{sectionData.mainMessage}</p>
      </div>
    </SectionWrapper>
  );
};

export default Hero;
