import "./FeatureCardsWrapper.scss";

// animate
import Animated from "../../Components/Animated";

const FeatureCardsWrapper = ({ features, cardBackground, cardFontColor }) => {
  return (
    <div className="feature-cards-wrapper">
      {features.map((feature, index) => {
        return (
          <Animated
            direction="up"
            delay={0.2 * index}
            className="feature-card"
            style={{
              background: cardBackground,
              color: cardFontColor,
              border: `3px solid ${cardFontColor}`,
            }}
            key={index}
          >
            <span className="emoji">{feature.emoji}</span>
            <div className="text-section">
              <span className="title">{feature.title}</span>
              <p className="description">{feature.description}</p>
            </div>
          </Animated>
        );
      })}
    </div>
  );
};

export default FeatureCardsWrapper;
