import React from "react";
import "./Signup.scss";

// wrapper
import SectionWrapper from "./Wrappers/SectionWrapper";
import SectionRowsWrapper from "./Wrappers/SectionRowsWrapper";

// lp Data
import lpData from "../Data/LPData";

const Signup = () => {
  const sectionData = lpData.Signup;

  return (
    <SectionWrapper
      bgColor={sectionData.background}
      fontColor={sectionData.fontColor}
      tight={true}
      wrapperClass="signup-wrapper"
      containerClass="signup-container"
      sectionTitle={sectionData.sectionTitle}
      sectionDescription={sectionData.sectionDescription}
    >
      <img src={sectionData.imgUrl} alt="" className="join-pulp-image" />
      
      <a href={sectionData.formUrl} target="_blank" className="signup-button">
        <span>{sectionData.buttonText}</span>
      </a>
    </SectionWrapper>
  );
};

export default Signup;
