import React from "react";
import "./Carousel.scss";

const Carousel = ({ children, className }) => {
  // ------ Variables -----------------
  const count = 2;
  const duration = 40;
  const gap = "30px";

  // ----- Functions ---------------
  const injectStyle = (styles) => {
    const styleElement = document.createElement("style");
    let styleSheet = null;

    document.head.appendChild(styleElement);

    styleSheet = styleElement.sheet;

    styles.forEach((style) => {
      styleSheet.insertRule(style, styleSheet.cssRules.length);
    });
  };

  const getDuration = () => {
    return duration * count;
  };

  const getDelay = (i) => {
    const index = count - 1 - i;
    const quicken = -1 * (duration * index);
    return quicken;
  };

  const getPositions = (i) => {
    const start = 0 + (100 * i);
    const end = start - 100 * count;

    return {
      start: `${start - 10}%`,
      end: `${end - 10}%`,
    }
  } 

  const getStyle = (i) => {
    const style = {
      animation: `scroll-${i} ${getDuration()}s ${getDelay(
        i
      )}s infinite linear`,
    };
    return style;
  };

  // ------ Effects ---------------------
  // add style sheet to dom to define animation
  React.useEffect(() => {
    const keyframes = [];

    Array(count)
      .fill()
      .map((_, i) => {
        const index = count - 1 - i;
        keyframes.push(
          `
            @keyframes scroll-${index} {
              0%   { transform: translateX(${getPositions(i).start}); }
              100%   { transform: translateX(${getPositions(i).end}); }
            }
          `.trim()
        );
      });

    // keyframes.forEach((keyframe) => {
    //   console.log(keyframe.replace(/\s/g, ""));
    // });

    injectStyle(keyframes);
  }, []);

  return (
    <div className={`carousel-wrapper ${className}`}>
      {Array(count)
        .fill()
        .map((_, i) => {
          return (
            <div className="slideshow" style={getStyle(i)} key={i}>
              {children.map((child, index) => {
                return (
                  <div
                    className="slide"
                    key={index}
                    style={{
                      margin: `0 ${gap}`,
                    }}
                  >
                    {child}
                  </div>
                );
              })}
            </div>
          );
        })}
    </div>
  );
};

export default Carousel;
