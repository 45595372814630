import React, { useRef } from "react";
import "./Creators.scss";

// axios
import axios from "axios";

// animation
import { animate, useInView } from "framer-motion";
import Animated from "../Components/Animated";

// icon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCertificate } from "@fortawesome/free-solid-svg-icons";
import {
  faTwitter,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

// lp data
import lpData from "../Data/LPData.js";

// wrapper
import SectionWrapper from "./Wrappers/SectionWrapper";

const Features = () => {
  const sectionData = lpData.Creators;

  const [blocksCount, setBlocksCount] = React.useState();

  // get blocks count
  React.useEffect(() => {
    axios
      .get(`${lpData.apiEndpoint}/lp/blocks_count`)
      .then((res) => {
        setBlocksCount(res.data.blocks_count);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // functions
  const Counter = ({ from, to }) => {
    const nodeRef = useRef();
    const isInView = useInView(nodeRef);

    React.useEffect(() => {
      const node = nodeRef.current;

      const controls = animate(from, to, {
        duration: 3,
        delay: 1.5,
        ease: [0.7, 0.7, 0, 1],
        onUpdate(value) {
          var newValue = parseInt(value).toLocaleString();
          node.textContent = newValue + " +";
        },
      });

      return () => controls.stop();
    }, [from, to, isInView]);

    return <span className="number" ref={nodeRef} />;
  };

  if (!sectionData.hidden) {
    return (
      <SectionWrapper
        bgColor={sectionData.background}
        fontColor={sectionData.fontColor}
        tight={true}
        wrapperClass="creators-wrapper"
        containerClass="creators-container"
        sectionTitle={sectionData.sectionTitle}
        sectionDescription={sectionData.sectionDescription}
      >
        <div className="badge">
          <FontAwesomeIcon icon={faCertificate} className="icon" />
        </div>

        {blocksCount && (
          <Animated direction="up" delay={0.5} className="stats-section">
            <div className="stats-container">
              <Counter
                from={0}
                to={parseInt(Math.round(blocksCount / 10) * 10)}
              />
              <p className="text">
                blocks
                <br />
                created.
              </p>
            </div>
          </Animated>
        )}

        <div className="creators-carousel-container">
          {sectionData.creators.map((creator, index) => {
            return (
              <Animated
                className="creator-container"
                key={index}
                direction="up"
                delay={0.5}
              >
                <span className="index">
                  {index + 1}/{sectionData.creators.length}
                </span>
                <div className="name-section">
                  <div className="name-wrapper">
                    <FontAwesomeIcon icon={faCertificate} className="icon" />
                    <div className="name-container">
                      {creator.occupation && (
                        <span className="occupation">{creator.occupation}</span>
                      )}
                      <span className="name">{creator.name} さん</span>
                    </div>
                  </div>

                  <img src={creator.image} className="image" alt="" />
                </div>

                <div className="comment-section">
                  <p className="comment">{creator.comment}</p>
                </div>
                <div className="social-section">
                  {creator.social && (
                    <a
                      className="social underline"
                      href={creator.social.url}
                      target="_blank"
                    >
                      <FontAwesomeIcon
                        icon={(() => {
                          switch (creator.social.platoform) {
                            case "IG":
                              return faInstagram;
                              break;
                            case "TW":
                              return faTwitter;
                              break;
                            case "YT":
                              return faYoutube;
                              break;
                            default:
                              break;
                          }
                        })()}
                        className="icon"
                      />
                      <span className="handle">{creator.social.handle}</span>
                    </a>
                  )}
                </div>
              </Animated>
            );
          })}
        </div>

        <div className="thankyou-section">
          <span className="thankyou">Thank you so much!!</span>
        </div>
      </SectionWrapper>
    );
  }
};

export default Features;
